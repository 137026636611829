<template>
  <v-container>
    <v-card>
      <v-data-table
        :headers="headersRDO"
        :items="RDO"
        :loading="loadingRDO"
        class="elevation-1"
        item-key="id"
        loading-text="Loading... Please wait"
      >
        <template v-slot:[`header.actions`]>
          <v-icon @click="refreshRdo()">mdi-refresh</v-icon>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small @click="updateDataRDO(item)"> mdi-eye </v-icon>
        </template>

      </v-data-table>
    </v-card>

    <v-dialog
      :value="dialogData"
      scrollable
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card v-if="this.dialogData" v-click-outside="toggleDialogData">
        <v-card-title primary-title>
          {{ this.dataRDO.metadata.technicalId }}
        </v-card-title>
        <json-viewer :value="dataRDO" copyable :expand-depth="2"> </json-viewer>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: "rdo-data-table",
  props: {
    loadingRDO: Boolean,
    dataRDO: Object,
    RDO: Array,
    headersRDO: Array,
    contentRange: Number,
  },
  data() {
    return {
      dialogData: false,
    };
  },
  methods: {
    updateDataRDO(id) {
      this.$emit("updateDataRDO", id);
    },

    refreshRdo() {
      this.$emit("refreshRdo");
    },

    toggleDialogData() {
      this.dialogData = !this.dialogData;
    },
  },
  watch: {
    dataRDO() {
      this.toggleDialogData();
    },
  },
};
</script>

<style></style>
