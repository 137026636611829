<template>
  <v-container fluid v-if="$store.getters.user.isAdminRDExpo === true">
    <v-row class="d-flex align-stretch">
      <!-- TABLE ROLES -->
      <v-col cols="12" sm="6" md="6">
        <v-card>
          <v-card-title primary-title>
            Roles-Rdo Portal RDO Platform
            <div class="pl-2"></div>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
            <v-col cols="4">
              <v-select
                :disabled="loadingRdo"
                :loading="loadingRdo"
                :items="listRdo"
                label="Select"
                item-text="_fieldsProto.Nomenclature GCP.stringValue"
                return-object
                single-line
                v-on:change="updateRdoSelected"
              >
                <template slot="selection" slot-scope="data">
                  {{
                    data.item["_fieldsProto"]["Nomenclature GCP"]["stringValue"]
                  }}
                </template>
                <template slot="item" slot-scope="data">
                  {{
                    data.item["_fieldsProto"]["Nomenclature GCP"]["stringValue"]
                  }}
                </template>
              </v-select>
            </v-col>
          </v-card-title>
          <v-data-table
            :headers="headersRdo"
            :items="rdoRoles"
            class="elevation-1"
            :items-per-page="5"
            :loading="loadingRdoRoles"
            :search="search"
          >
            <template v-slot:[`header.actions`]>
              <v-icon @click="refresh()">mdi-refresh</v-icon>
            </template>
            <template v-slot:[`item.Default`]="{ item }">
              <v-icon v-if="item.Default === true"  @click="not(item,'Default')">mdi-check</v-icon>
              <v-icon v-else @click="not(item,'Default')">mdi-close</v-icon>
            </template>
            <template v-slot:[`item.Qualif`]="{ item }">
              <v-icon v-if="item.Qualif === true" @click="not(item,'Qualif') ">mdi-check</v-icon>
              <v-icon v-else @click="not(item,'Qualif') ">mdi-close</v-icon> 
            </template>
            <template v-slot:[`item.Inventaire`]="{ item }">
              <v-icon v-if="item.Inventaire === true" @click="not(item,'Inventaire') ">mdi-check</v-icon>
              <v-icon v-else @click="not(item,'Inventaire') ">mdi-close</v-icon>
            </template>
            <template v-slot:[`item.Purge`]="{ item }">
              <v-icon v-if="item.Purge === true" @click="not(item,'Purge') ">mdi-check</v-icon>
              <v-icon v-else @click="not(item,'Purge') ">mdi-close</v-icon>
            </template>
            <template v-slot:[`item.TOV`]="{ item }">
              <v-icon v-if="item.TOV === true && isTov()==true" @click="not(item,'TOV') ">mdi-check</v-icon>
              <v-icon v-else-if="item.TOV === false && isTov()==true" @click="not(item,'TOV') ">mdi-close</v-icon>
              <v-icon v-else >mdi-close</v-icon>
            </template>
            <template v-slot:[`item.PO`]="{ item }">
              <v-icon v-if="item.PO === true" @click="not(item,'PO') ">mdi-check</v-icon>
              <v-icon v-else @click="not(item,'PO') ">mdi-close</v-icon>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn small @click="sendroles(item)">
                <v-icon>mdi-update</v-icon>
              </v-btn>
            </template>
            <template >
              
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "data-list-roles-rdo",
  props: {
    loadingRdo: Boolean,
    loadingRdoRoles: Boolean,
    listRdo: Array,
    rdoRoles: Array,
    headersRdo: Array,
    nextId: Number,
    getUsersList: Function,
    getRdoList: Function,
    updateRdoSelect: Function,
    swtich: Function,
    sendRight : Function,
  },

  data() {
    return {
      rdoSelected: null,
      search: "",
    };
  },

  methods: {

    updateRdoSelected(a) {
      this.rdoSelected = a;
      this.updateRdoSelect(a);
    },
    refresh() {
      this.updateRdoSelect(this.rdoSelected);
    },
    save(){},
    not(item,roles){
      item[roles] = !item[roles]
    },
    isTov(){
      try {
          if (this.rdoSelected['Nomenclaturec GCP'].stringValue.includes('tableofvalue') == true) {
            return true
          }
          else {
            return false
          }
        }
        catch (error) {
          if (error instanceof TypeError) {
            return false
          }
          else {
            console.log("[DataListRolesRdo.vue ] - isTov() - Probléme non reconnu " + error)
          }
        }
      
    },
    sendroles(right){
      this.sendRight(right.Email,right,this.rdoRoles,this.rdoSelected)
    }
  },
};
</script>

<style></style>
