const axios = require("axios");
import store from "../store/store";
const url = `${store.getters.apiUrl}`;

export default {
  methods: {
    getSubRequests() {
      const name = store.getters.user.data.displayName;
      let config = {
        headers: {
          authorization: `Bearer ${store.getters.user.token}`,
        },
      };
      let request = `${url}/subrequest/${name}`;
      axios
        .get(request, config)
        .then((results) => {
          this.subRequests = results.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    newSubRequest(name) {
      let config = {
        headers: {
          authorization: `Bearer ${store.getters.user.token}`,
        },
      };
      let request = `${url}/subrequest/`;
      let body = {
        id_applicant: store.getters.user.data.id,
        mail_applicant: store.getters.user.data.email,
        po_data:
          store.getters.rdoSelected["_fieldsProto"]["PO Data"][
            "stringValue"
          ].toUpperCase(),
        rdo: store.getters.rdoSelected["_fieldsProto"]["Nomenclature GCP"][
          "stringValue"
        ],
      };
      switch (name) {
        case "Prod vers Qualif":
          body["future_role_id"] = 1;
          body["future_role_name"] = "Alim Prod -> Qualif";
          break;
        case "Inventaire":
          body["future_role_id"] = 2;
          body["future_role_name"] = "Mise à jour Inventaire";
          break;
        case "Purge":
          body["future_role_id"] = 3;
          body["future_role_name"] = "Purger";
          break;
        case "MAJ TOV":
          body["future_role_id"] = 4;
          body["future_role_name"] = "Mise à jour TOV";
          break;
        default:
          break;
      }
      axios
        .post(request, body, config)
        .then((results) => {
          alert("Demande Envoyée");
        })
        .catch((error) => {
          console.log(error);
        });
    },

    answerRequest(subRequest, accepted) {
      let config = {
        headers: {
          authorization: `Bearer ${store.getters.user.token}`,
        },
      };
      let request = `${url}/subrequest/`;
      let body = subRequest;
      body.accepted = accepted;
      axios
        .patch(request, body, config)
        .then((results) => {
          if (accepted) {

            alert("Demande Acceptée")
            this.getSubRequests()
          }
          else {
            alert("Demande Refusée")
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    downgrade(name) {
      let config = {
        headers: {
          authorization: `Bearer ${store.getters.user.token}`,
        },
      };
      let request = `${url}/downgrade/`;
      let body = {
        id_applicant: store.getters.user.data.id,
        mail_applicant: store.getters.user.data.email,
        po_data:
          store.getters.rdoSelected["_fieldsProto"]["PO Data"][
            "stringValue"
          ].toUpperCase(),
        rdo: store.getters.rdoSelected["_fieldsProto"]["Nomenclature GCP"][
          "stringValue"
        ],
      };
      switch (name) {
        case "Prod vers Qualif":
          if (store.getters.user.currentRole.includes("1")) {
            let tab_role = store.getters.user.currentRole.split("|");
            const index = tab_role.indexOf("1");
            if (index > -1) {
              // only splice array when item is found
              tab_role.splice(index, 1); // 2nd parameter means remove one item only
            }
            body["future_role_id"] = tab_role.join("|");
          }
          break;

        case "Purge":
          if (store.getters.user.currentRole.includes("3")) {
            let tab_role = store.getters.user.currentRole.split("|");
            const index = tab_role.indexOf("3");
            if (index > -1) {
              // only splice array when item is found
              tab_role.splice(index, 1); // 2nd parameter means remove one item only
            }
            body["future_role_id"] = tab_role.join("|");
          }
          break;
        case "MAJ TOV":
          if (store.getters.user.currentRole.includes("4")) {
            let tab_role = store.getters.user.currentRole.split("|");
            const index = tab_role.indexOf("4");
            if (index > -1) {
              // only splice array when item is found
              tab_role.splice(index, 1); // 2nd parameter means remove one item only
            }
            body["future_role_id"] = tab_role.join("|");
          }
          break;

        default:
          break;
      }
      axios
        .patch(request, body, config)
        .then((results) => {
          alert("Downgrade Purge !");
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
