import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

import colors from 'vuetify/lib/util/colors'
import store from '../store/store';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: store.getters.mainColor,
                background: colors.grey.lighten5,
            }
        }
    }
});
