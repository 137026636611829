<template>
  <div>
    <v-dialog
      :value="dialogreplay"
      :overlay="false"
      max-width="700px"
      transition="dialog-transition"
      persistent
      v-if="rdoSelected !== null"
    >
      <v-card v-if="this.apiRDO !== undefined">
        <v-card-title primary-title>
          Vous êtes sur le point d'effectuer une reprise de document
        </v-card-title>
        <v-card-text>
          <b>
            RDO sélectionné : {{ rdoSelected["_fieldsProto"]["Nomenclature GCP"]["stringValue"] }}<br />
          </b>
        </v-card-text>
        <v-card-text v-if="isProjectId() == true">
          projectId: {{ apiRDO.projectId }}
        </v-card-text>
        <v-card-text>
          Paramètres:
          <v-row class="d-flex">
            <v-col cols="3">
              <v-select
                :items="items"
                v-model="fieldSelected"
                label="Rechercher"
                @input="updateType"
              ></v-select>
            </v-col>
            <v-col cols="2">
              <v-select
                v-model="connectorSelected"
                hide-details
                :items="connectors"
              ></v-select>
            </v-col>
            <v-col cols="2">
              <v-text-field
                label="Valeur"
                v-model="valueParam"
                :type="dataType"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col v-if="this.fieldSelected !== undefined && this.connectorSelected !== undefined && this.valueParam" cols="2">
              <v-btn @click="addParameter()">+</v-btn>
            </v-col>
            <v-col cols="12">
              <v-combobox
                flat
                multiple
                solo
                readonly
                hide-details
                append-icon
                chips
                :items="addedParams"
                :value="addedParams"
                background-color="background"
                ><template slot="selection" slot-scope="{ item, index }"
                  ><v-chip
                    close
                    small
                    outlined
                    color="primary lighten-2"
                    @click:close="deleteParameter(index)"
                    ><span> {{ item }} </span></v-chip
                  ></template
                >
              </v-combobox>
              <p v-if="this.query != null">{{ query }}</p>
              <p v-if="this.topicName != null">
                Topic sur lequel vous devrez souscrire pour récuperer les
                données : {{ topicName }}
              </p>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-col cols="3">
            <v-text-field
              required
              min="0"
              max="10"
              label="Durée de validité du topic"
              @input="setTopic"
              v-model="validityDuration"
              type="number"
              hide-details
            ></v-text-field>
          </v-col>
          <v-btn
            color="error"
            v-if="this.whereCondition != null"
            @click="replayVerif()"
          >replay</v-btn>
          <v-btn text @click="toggleDialogreplay()">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Boîte de dialogue "En êtes-vous sûr" -->
    <v-dialog
      :value="dialogSur"
      :overlay="false"
      max-width="700px"
      transition="dialog-transition"
      persistent
      v-if="rdoSelected !== null"
    >
      <v-card v-if="this.apiRDO !== undefined">
        <v-card-title primary-title>En êtes-vous sûr de reprendre ses documents ?</v-card-title>
        <v-card-text>
          Vous avez sélectionné {{ numberOfDoc }} document{{ numberOfDoc > 1 ? "s" : "" }} qui seront repris en cas de reprise. Voulez-vous continuer ? Coût approximatif : {{ price }}$
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="replay()">Oui, je suis sûr</v-btn>
          <v-btn text @click="toggleDialogSur()">Fermer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Boîte de dialogue visible en QUALIF et DEV -->
    <v-dialog
      :value="dialogVerif"
      :overlay="false"
      max-width="700px"
      transition="dialog-transition"
      persistent
      v-if="rdoSelected !== null && (env === 'HPRD' || env === 'DEV')"
    >
      <v-card v-if="this.apiRDO !== undefined">
        <v-card-title primary-title>
          Voulez-vous vraiment replayr les {{ numberOfDoc }} documents ?
        </v-card-title>
        <v-card-text>
          Vous n'avez sélectionné aucun paramètre. Si vous décidez de replay-er,
          c'est le RDO entier qui sera purgé. Voulez-vous continuer ?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="replay()">Oui, je veux replayr TOUT le RDO</v-btn>
          <v-btn text @click="toggleDialogVerif()">Fermer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Boîte de dialogue visible en PROD -->
    <v-dialog
      :value="dialogVerif"
      :overlay="false"
      max-width="700px"
      transition="dialog-transition"
      persistent
      v-if="rdoSelected !== null && env === 'PROD'"
    >
      <v-card v-if="this.apiRDO !== undefined">
        <v-card-title primary-title>replay impossible</v-card-title>
        <v-card-text>
          La replay n'est pas disponible en n'ayant sélectionné aucun paramètre.
          Veuillez contacter l'équipe RDO pour supprimer la totalité des données
          du RDO.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="toggleDialogVerif()">Fermer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import store from "../../store/store";
import exceptionsView from "../../utils/exceptionsView.json";
const moment = require("moment");
const axios = require("axios");

export default {
  name: "serv-replay",
  props: {
    dialogreplay: Boolean,
    toggleDialogreplay: Function,
    rdoSelected: Object,
    apiRDO: Object,
  },
  data: () => ({
    items: ["site", "processDate", "processApp"],
    addedParams: [],
    numberOfDoc: 0,
    price: 0,
    lake: null,
    url: `${store.getters.apiUrl}`,
    validityDuration: 3,
    whereCondition: null,
    fieldSelected: null,
    connectors: null,
    connectorSelected: null,
    env: store.getters.env,
    queryEscaped: "",
    dialogVerif: false,
    dialogSur: false,
    search: "",
    dialogErrorParameter: false,
    dataType: "",
    valueParam: null,
    query: null,
    topicName: null,
    res: null,
  }),
  methods: {
    async replay() {
      this.queryEscaped = encodeURI(this.query);
      this.dialogVerif = false;
      this.dialogSur = false;
      this.doreplay();
      this.toggleDialogreplay();
    },
    setNull() {
      this.query = null;
      this.addedParams = [];
      this.topicName = null;
    },
    setTopic() {
      var rightNow = new Date();
      this.res = rightNow.toISOString().slice(0, 10).replace(/-/g, "");
      this.topicName = `pubsubreplay-${this.rdoSelected["_fieldsProto"]["Nomenclature GCP"]["stringValue"]}-${this.res}-${this.validityDuration}`;
    },
    deleteParameter(parameter) {
      this.addedParams.splice(parameter, 1);
      if (this.addedParams.length === 0) {
        this.setNull();
      }
      this.setQuery();
    },
    replayVerif() {
      const countQuery = this.query.replace("*", "count(distinct(key))");
      let request = `${this.url}/countDocs`;
      let config = {
        headers: {
          authorization: `Bearer ${store.getters.user.token}`,
        },
        data: {
          query: countQuery,
          lake: this.lake,
          queryAll: this.query,
        },
      };
      axios
        .post(request, config)
        .then((results) => {
          this.numberOfDoc = results.data[0]["f0_"];
          this.price = results.data[0]["cost"];
          if (this.addedParams.length === 0) {
            this.dialogVerif = true;
          } else if (this.addedParams.length > 0) {
            this.dialogSur = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateConnector(a) {
      this.connectorSelected = a;
    },
    updateValue(a) {
      this.valueParam = a;
    },
    updateType(a) {
      if (a.includes("date") || a.includes("Date")) {
        this.fieldSelected = a;
        this.dataType = "date";
        this.connectors = ["=", "!=", "lt", "lte", "gt", "gte"];
      } else {
        this.fieldSelected = a;
        this.dataType = "string";
        this.connectors = ["=", "!="];
      }
    },
    toggleDialogVerif() {
      this.dialogVerif = false;
      this.toggleDialogreplay();
    },
    addParameter() {
      this.setTopic();
      if (this.fieldSelected.includes("date") || this.fieldSelected.includes("Date")) {
        this.valueParam = `${moment(this.valueParam).format("YYYY-MM-DD")}`;
      }
      this.addedParams.push("" + this.fieldSelected + "|" + this.connectorSelected + "|" + this.valueParam + "");
      this.fieldSelected = null;
      this.connectorSelected = null;
      this.valueParam = null;
      this.setQuery();
    },
    setQuery() {
      let field, operatore, value;
      let dateDetect;
      let PublishTimeOperator = "=";
      let PublishTimeDate = moment().format("YYYY-MM-DD");
      if (this.addedParams.length > 0) {
        let query = "SELECT * FROM ";
        let viewName = Object.keys(exceptionsView).includes(this.rdoSelected["_fieldsProto"]["Nomenclature GCP"]["stringValue"])
          ? exceptionsView[this.rdoSelected["_fieldsProto"]["Nomenclature GCP"]["stringValue"]]
          : this.rdoSelected["_fieldsProto"]["Nomenclature GCP"]["stringValue"];
        this.lake = this.env === "HPRD"
          ? "arf-data-lake-hprd"
          : this.env === "DEV"
          ? "arf-data-lake-dev"
          : "arf-data-lake";
        viewName = viewName.replace("-", "_").toUpperCase();
        const lakeView = "`" + this.lake + ".ARF_RDO.RDO_" + viewName + "` WHERE ";
        let whereCondition;
        for (let index = 0; index < this.addedParams.length; index++) {
          const element = this.addedParams[index];
          const elementList = element.split("|");
          field = elementList[0] != "site"
            ? "content.metadata." + elementList[0]
            : "content.data." + elementList[0];
          field = field.includes("date") || field.includes("Date")
            ? `date(${field})`
            : field;
          dateDetect = field.includes("processDate") ? true : false;
          operatore = elementList[1];
          value = elementList[2];
          if (dateDetect) {
            PublishTimeOperator = operatore;
            PublishTimeDate = value;
          }
          whereCondition = index != 0
            ? `${whereCondition} AND ${field}${operatore}"${value}"`
            : `${field}${operatore}"${value}"`;
        }
        this.whereCondition = whereCondition;
        query += lakeView + this.whereCondition + ` AND date(publishTime)${PublishTimeOperator}"${PublishTimeDate}"`;
        this.query = query;
      } else {
        this.query = null;
      }
    },
    toggleDialogSur() {
      this.setNull();
      this.dialogSur = false;
      this.toggleDialogreplay();
    },
    isProjectId() {
      try {
        if (this.apiRDO.projectId == true) {
          return true;
        } else {
          return false;
        }
      } catch (error) {
        if (error instanceof TypeError) {
          return false;
        } else {
          console.log("[Servereplay.vue ] - isProjectId() - Probléme non reconnu " + error);
        }
      }
    },
    async doreplay() {
      const clusterId =
        this.env === "HPRD" ? "arf-rdo-cluster1-hprd" : "arf-rdo-cluster1";
      let config = {
        headers: {
          authorization: `Bearer ${store.getters.user.token}`,
        },
        data: {
          query: this.query,
          clusterId: clusterId,
          topicName: this.topicName,
        },
      };

      let request = `${this.url}/replay`;
      axios
        .post(request, config)
        .then((results) => {
          console.log("done => " + JSON.stringify(results));
          alert(results.data.message);
          this.$emit("refreshRdo");
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style></style>
