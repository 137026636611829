import store from "../store/store";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
  setPersistence,
} from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration for DEV
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);

export default {
  methods: {
    logIn() {
      let user = {};
      if (!auth.currentUser) {
        var provider = new GoogleAuthProvider();
        provider.setCustomParameters({
          prompt: "select_account",
        });
        signInWithPopup(auth, provider)
          .then(async (results) => {
            if (
              results.user.email.endsWith("@auchan.fr") ||
              results.user.email.endsWith("@partner.auchan.fr") ||
              results.user.email.endsWith("@partner.auchan.com") ||
              results.user.email.endsWith("@auchan.com")
            ) {
              await store.dispatch("setToken", results.user.accessToken);
              user["displayName"] = results.user.displayName;
              user["email"] = results.user.email;
              user["icon"] = results.user.photoURL;
              await this.afterLogIn(user);
            } else {
              auth.currentUser = null;
              this.toggleNotAuchan();
            }
          })
          .catch((err) => {
            auth.currentUser = null;
            console.log(err.message);
          });
      }
    },

    logOut() {
      signOut(auth)
        .then(async () => {
          await store.dispatch("setUser", null);
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
