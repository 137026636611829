//used in webapp/src/components/rdoNavBar.vue

const axios = require("axios");
import store from "../store/store";
const url = `${store.getters.apiUrl}`;

export default {
    methods: {
        async purge() {
            const query = new URLSearchParams();
            query.append("rdo", this.apiRDO.projectId);
            query.append("currentroles", store.getters.user.currentRole);
            for (const el of this.addedParams) {
                query.append(el.key, el.value);
            }
            let config = {
                headers: {
                    authorization: `Bearer ${store.getters.user.token}`,
                },
                params: query,
            };
            let request = `${url}/purge`;
            axios
                .get(request, config)
                .then((results) => {
                    if (results.data.status === 200) {
                        alert(results.data.message);
                    } else {
                        if (window.confirm(results.data)) {
                            window.open(
                                "mailto:fr-liste_gcp_admin_rdo@auchan.fr?subject=Demande%20de%20Purge%20totale%20d'un%20RDO",
                                "_newtab"
                            );
                        }
                    }
                    this.$emit("refreshRdo");
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    },
};