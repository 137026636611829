<template>
  <div>
    <data-list-users
      v-bind:roles="roles"
      v-bind:loadingRoles="loadingRoles"
      v-bind:users="users"
      v-bind:loadingUsers="loadingUsers"
      v-bind:headersRoles="headersRoles"
      v-bind:headersUsers="headersUsers"
      v-bind:nextId="nextId"
      v-bind:addUserReq="addUserReq"
      v-bind:deleteUserReq="deleteUserReq"
    />
  </div>
</template>

<script>
import DataListUsers from "../components/DataListUsers.vue";
import PoManagement from "../scripts/poManagement";
export default {
  name: "POMANAGE",
  mixins: [PoManagement],
  components: {
    DataListUsers,
  },
  data() {
    return {
      roles: [],
      loadingRoles: true,
      users: [],
      loadingUsers: true,
      headersRoles: [
        {
          text: "Name",
          align: "left",
          value: "_ref._path.segments[1]",
          sortable: false,
        },
        {
          text: "Inventaire",
          align: "left",
          value: "_fieldsProto.right-inventory.booleanValue",
          sortable: false,
        },
        {
          text: "Qualif",
          align: "left",
          value: "_fieldsProto.right-qualif.booleanValue",
          sortable: false,
        },
        {
          text: "Purge",
          align: "left",
          value: "_fieldsProto.right-purge.booleanValue",
          sortable: false,
        },
        {
          text: "Supv Tech",
          align: "left",
          value: "_fieldsProto.right-supv-tech.booleanValue",
          sortable: false,
        },
        {
          text: "Supv Fonc",
          align: "left",
          value: "_fieldsProto.right-supv-fonc.booleanValue",
          sortable: false,
        },
        {
          text: "Admin RDO",
          align: "left",
          value: "_fieldsProto.right-access.booleanValue",
          sortable: false,
        },
      ],
      headersUsers: [
        {
          text: "Email",
          align: "left",
          value: "_ref._path.segments[1]",
        },
        {
          text: "Nom",
          align: "left",
          value: "_fieldsProto.name.stringValue",
        },
        {
          text: "Prenom",
          align: "left",
          value: "_fieldsProto.firstname.stringValue",
        },
        {
          text: "Admin RDExpo",
          align: "left",
          value: "_fieldsProto.isAdminRDExpo.booleanValue",
        },
        { value: "actions", sortable: false },
      ],
    };
  },

  mounted: function () {
    setTimeout(() => {
      this.loadingRoles = false;
      this.loadingUsers = false;
    }, 5000);
    this.getRolesList();
    this.getUsersList();
  },

  methods: {
    test(e) {
      console.log(e);
    },
  },

};
</script>

<style></style>
