const axios = require("axios");
import store from "../store/store";

const url = `${store.getters.apiUrl}`;

export default {
  methods: {


    async getRights(id, rdo) {
      let config = {
        headers: {
          authorization: `Bearer ${store.getters.user.token}`,
        },
      };
      let request = `${url}/roles/${id}`;
      const results = await axios.get(request, config);
          let obj = {};
          if (rdo.includes("tableofvalue")) {

            obj["TOV"] = results.data["right-tov"] || false;
          }
          else {
            obj["TOV"] = false;
          }
          obj["PO"] = results.data["right-po"] || false;
          obj["Inventaire"] = results.data["right-inventory"] || false;
          obj["Purge"] = results.data["right-purge"] || false;
          obj["Qualif"] = results.data["right-qualif"] || false;
          if ((results.data["right-supv-fonc"]&&results.data["right-supv-tech"])==true){
            obj["Default"] = true
          }
          else {
            obj["Default"] = false
          }        
        
          return obj
    
    },

    async sendRight(email,newright,rdoRoles,rdo) {
      let idUser= null;
      for (let index = 0; index < rdoRoles.length; index++) {
        if (rdoRoles[index].Email == email) {
          idUser=index;
        }
      }
      
      let obj = {};
      if (newright["TOV"]!=false) {obj["right-tov"]=true}
      if (newright["Inventaire"]!=false) {obj["right-inventory"]=true}
      if (newright["Purge"]!=false) {obj["right-purge"]=true}
      if (newright["Qualif"]!=false) {obj["right-qualif"]=true}
      if (newright["PO"]!=false) {obj["right-po"]=true}
      obj["right-supv-fonc"]=true; 
      obj["right-supv-tech"]=true;
      let config = {
        headers: {
          authorization: `Bearer ${store.getters.user.token}`,
        },
      };
      let body= {
        rdo : rdo,
        idUser: idUser,
        newRoles : obj,
        email : email
      }
      let request = `${url}/roles_rdo`;
      await axios.post(request,body,config)
      .then((results) => {})
      .catch((error) => {
        console.log(error);
      });
    },


    async getRDORole(rdo) {
      let config = {
        headers: {
          authorization: `Bearer ${store.getters.user.token}`,
        },
      };
      let request = `${url}/roles_rdo/${rdo}`;
      const results = await axios.get(request, config);
      if (results != null) {
          let x = results.data;
          for (let userIndex = 0; userIndex < this.users.length; userIndex++) {
            const element = this.users[userIndex]
            let id = element._fieldsProto.id.integerValue
            if (Object.prototype.hasOwnProperty.call(x, parseInt(id))) {
              this.rdoRoles.push(await this.getRights(x[id].roles, rdo))
              this.rdoRoles[userIndex]= Object.assign(this.rdoRoles[userIndex],{ 
                Email : this.users[userIndex]._fieldsProto.mail.stringValue
              })
              
            } else {
              this.rdoRoles.push(await this.getRights(x[id].roles, rdo))
              this.rdoRoles[userIndex]= Object.assign(this.rdoRoles[userIndex],{ 
                Email : this.users[userIndex]._fieldsProto.mail.stringValue
            })}
          }
        }
        return this.rdoRoles
    },

    
    
    async updateRdoSelect(variable) {
      this.loadingRdoRoles = true;
      this.rdoSelected = variable;
      try {
        let rdo =
          this.rdoSelected["_fieldsProto"]["Nomenclature GCP"]["stringValue"];
        this.rdoRoles=[]
        
        this.rdoRoles = await this.getRDORole(rdo);
        this.loadingRdoRoles = false
      }
      catch (error) {
        if (!(error instanceof TypeError)) {
          console.log(error)
        }
      }
    },












    async getRdoList() {
      let config = {
        headers: {
          authorization: `Bearer ${store.getters.user.token}`,
        },
      };
      let request = `${url}/rdo`;
      axios
        .get(request, config)
        .then((results) => {
          let tmp = [];
          results.data.forEach((element) => {
            tmp.push(element);
          });
          this.listRdo = tmp;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getUsersList() {
      this.loadingUsers = true;
      let config = {
        headers: {
          authorization: `Bearer ${store.getters.user.token}`,
        },
      };
      let request = `${url}/users`;
      axios
        .get(request, config)
        .then((results) => {
          this.users = results.data;
          this.loadingUsers = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getRolesList() {
      this.loadingRoles = true;
      let config = {
        headers: {
          authorization: `Bearer ${store.getters.user.token}`,
        },
      };
      let request = `${url}/roles`;
      axios
        .get(request, config)
        .then((results) => {
          this.roles = results.data;
          this.loadingRoles = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    addUserReq(mail, nom, prenom, admin) {
      let obj = {
        mail: mail,
        name: nom.toUpperCase(),
        firstname: prenom.toUpperCase(),
        isAdminRDExpo: admin,
      };
      let config = {
        headers: {
          authorization: `Bearer ${store.getters.user.token}`,
        },
      };
      let request = `${url}/users`;
      axios
        .post(request, obj, config)
        .then((results) => {
          this.getUsersList();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    deleteUserReq(name) {
      let config = {
        headers: {
          authorization: `Bearer ${store.getters.user.token}`,
        },
        data: { name }
      };
      let request = `${url}/users`;
      axios
        .delete(request, config,)
        .then((results) => {
          this.getUsersList();
        })
        .catch((error) => {
          console.log(error);
        });
    },

  },
};
