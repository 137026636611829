<template>
  <div>
    <v-dialog
      :value="dialogTovRemove"
      :overlay="false"
      max-width="1000px"
      transition="dialog-transition"
      persistent
    >
      <v-card>
        <v-card-title primary-title>Supprimer une table de valeur (ou une partie)</v-card-title>
        <v-card-text>
          <v-container>
            <v-text-field
              v-model="nomTov" 
              label="Nom de la table de valeur*" 
              required
            ></v-text-field>
            <v-text-field
              v-model="codeTov" 
              label="Code d'une valeur à supprimer" 
              required
            ></v-text-field>
          </v-container>
          <small>* Champs Requis</small><br />
          <small>
            Pour l'exemple de table de valeur ci-dessous, la nom de la table de valeur est 'SITEFORMAT'. Si vous souhaitez supprimer seulement la partie correspond à { "tableOfValuesCode": "DRL", "tableOfValuesDefinition": "DRIVE LOCKER" }, le code à resneigner est 'DRL'.<br />
            Si vous souhaitez supprimer seulement la partie correspond à { "settingsCode": "2", "settingsLabel": "test_2" }, le code à renseigner est 'DRL/tableOfValuesSettings/2'.<br />
            {<br />
            "tableOfValuesTable": "siteFormat",<br />
            "tableOfValuesList": [<br />
            { "tableOfValuesCode": "DRL", "tableOfValuesDefinition": "DRIVE LOCKER", "tableOfValuesSettings": [{ "settingsCode": "1", "settingsLabel": "test_1" }, { "settingsCode": "2", "settingsLabel": "test_2" }] },<br />
            { "tableOfValuesCode": "DIF", "tableOfValuesDefinition": "Différentiation" }<br />
            ]<br />
            }
          </small>
          <div class="v-alert error" v-if="errorTov">
            <p>Une erreur s'est produite : {{ this.errorMessage }}</p>
          </div>
          <div>
            <p class="v-alert success" v-if="tovOk">Mise à jour effectuée</p>
          </div>
          <div class="text-center">
            <v-progress-circular
              v-if="loader === true && env === 'DEV'"
              :size="70"
              :width="5"
              color="amber"
              indeterminate
            ></v-progress-circular>
            <v-progress-circular
              v-if="loader === true && env === 'HPRD'"
              :size="70"
              :width="5"
              color="green"
              indeterminate
            ></v-progress-circular>
            <v-progress-circular
              v-if="loader === true && env === 'PROD'"
              :size="70"
              :width="5"
              color="blue"
              indeterminate
            ></v-progress-circular>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="clear();toggleDialogTovRemove();">Fermer</v-btn>
          <v-btn
            color="red lighten-2"
            aria-disabled="false"
            v-if="this.nomTov !== ''"
            text
            @click.native="tovExist();"
          >Supprimer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Boîte de dialogue "Voulez-vous vraiment modifier / créer ?" -->
    <v-dialog
      :value="dialogTovExist"
      :overlay="false"
      max-width="700px"
      transition="dialog-transition"
      persistent
    >
      <v-card>
        <v-card-title primary-title>En êtes-vous sûr ?</v-card-title>
        <v-card-text>
          {{ this.handleTovExist === true ? "La table de valeur existe. Êtes-vous sûr de vouloir effectuer la suppression ?" : "La table de valeur n'existe pas." }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="removeTov(); toggleDialogTovExist();" v-if="this.handleTovExist === true">Oui</v-btn>
          <v-btn text @click="clear(); toggleDialogTovExist(); toggleDialogTovRemove();">{{ this.handleTovExist === true ? "Non" : "Fermer" }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import store from "../../store/store";
import rdoScripts from "../../scripts/rdoScripts";
import rdoRequests from "../../scripts/rdoRequests";

export default {
  name: "serv-tov-remove",
  mixins: [rdoScripts, rdoRequests],
  props: {
    dialogTovRemove: Boolean,
    toggleDialogTovRemove: Function,
    rdoSelected: Object,
  },
  data() {
    return {
      env : store.getters.env,
      errorTov: false,
      errorMessage: "",
      tovOk: false,
      tov: "",
      nomTov: "",
      codeTov: "",
      graviteeKey: "",
      handleTovExist: false,
      dialogTovExist: false,
      loader: false
    };
  },
  methods: {
    clear() {
      this.errorTov = false
      this.errorMessage = ""
      this.tovOk = false
      this.tov = ""
      this.handleTovExist = false
      this.nomTov = ""
      this.codeTov = ""
    },

    toggleDialogTovExist() {
      this.dialogTovExist = !this.dialogTovExist;
    },

    async tovExist() {
      this.errorTov = false;
      const response = await this.tovIsExist(this.nomTov);

      if (!response) {
        this.errorMessage = "La requête a échoué"
        this.errorTov = true;
      }

      if (response.status === 200) {
        this.handleTovExist = true
        return this.dialogTovExist = true
      } else if (response.status === 202) {
        this.handleTovExist = false
        return this.dialogTovExist = true
      }
    },

    async removeTov() {
      this.loader = true;
      this.errorTov = false;
      this.tovOk = false;
      const request = await this.deleteTov(this.nomTov, this.codeTov);

      if (request.status !== 202) {
        this.loader = false;
        this.errorMessage = "La requête a échoué"
        this.errorTov = true;
      } else {
        this.loader = false;
        this.tovOk = true;
        this.nomTov = ""
        this.codeTov = ""
        setTimeout(() => {
          this.toggleDialogTovRemove();
          this.clear();
          this.$emit("refreshRdo");
        }, 2000);
      }
    },

    isJson(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },
  },
  mounted: function () {
  },
};
</script>

<style></style>
