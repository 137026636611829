<template>
  <v-container fluid v-if="$store.getters.user.isAdminRDExpo === true">
    <v-alert v-model="alertCreate" text type="success" dismissible transition="scale-transition">
      User {{ userAlert }} created
    </v-alert>
    <v-alert v-model="alertDelete" text type="warning" dismissible transition="scale-transition">
      User {{ userAlert }} deleted
    </v-alert>

    <v-row class="d-flex align-stretch">
      <!-- TABLE ROLES -->
      <v-col cols="12" sm="6" md="5">
        <v-card>
          <v-card-title primary-title> Roles Portal RDO Platform</v-card-title>
          <v-data-table :headers="headersRoles" :items="roles" class="elevation-1" :items-per-page="10"
            hide-default-footer :loading="loadingRoles">
            <template v-slot:[`header.actions`]>
              <v-icon @click="refreshRoles()">mdi-refresh</v-icon>
            </template>
          </v-data-table>
        </v-card>

      </v-col>

      <v-col cols="12" sm="6" md="6">
        <v-card>
          <v-card-title primary-title>
            Users Portal RDO Platform
            <div class="pl-2"></div>
            <v-spacer></v-spacer>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details>
            </v-text-field>
          </v-card-title>
          <v-data-table :headers="headersUsers" :items="users" class="elevation-1" :items-per-page="5"
            :loading="loadingUsers" :search="search">
            <template v-slot:[`header.actions`]>
              <v-icon @click="refreshUsers()">mdi-refresh</v-icon>
            </template>
            <template v-slot:[`footer.prepend`]>
              <v-btn small @click="ocDialogAddUser()">
                <v-icon>mdi-account-plus</v-icon>
              </v-btn>
            </template>
            <template v-slot:[`item._fieldsProto.isAdminRDExpo.booleanValue`]="{ item }">
              <v-icon v-if="item._fieldsProto.isAdminRDExpo.booleanValue === true">mdi-check</v-icon>
              <v-icon v-else>mdi-close</v-icon>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small @click="deleteUser(item._ref._path.segments[1])">
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog :value="dialogAddUser" persistent :overlay="false" max-width="500px" transition="dialog-transition">
      <v-card>
        <v-card-title primary-title>
          <v-icon class="pr-2">mdi-account-plus</v-icon>
          Ajouter Utilisateur
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <v-text-field v-model="email" label="Email*"></v-text-field>
              </v-col>
              <v-col>
                <v-checkbox v-model="isAdmin" label="Admin RDExpo"></v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field v-model="nom" label="Nom*" required></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field v-model="prenom" label="Prenom*" required></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <small>* Champs obligatoires</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" v-if="
            this.email !== '' && this.nom !== '' && this.prenom !== ''
          " @click="addUser(email, nom, prenom, isAdmin)">Ajouter</v-btn>
          <v-btn text @click="ocDialogAddUser()">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: "data-list-users",
  props: {
    roles: Array,
    loadingRoles: Boolean,
    users: Array,
    loadingUsers: Boolean,
    headersRoles: Array,
    headersUsers: Array,
    nextId: Number,
    addUserReq: Function,
    deleteUserReq: Function,
    getRolesList: Function,
    getUsersList: Function,
  },

  data() {
    return {
      search: "",
      alertCreate: false,
      alertDelete: false,
      nom: "",
      prenom: "",
      email: "",
      isAdmin: false,
      userAlert: "",
      dialogAddUser: false,
    };
  },

  methods: {
    alertDisplay(type) {
      switch (type) {
        case "create":
          this.alertCreate = true;
          break;
        case "delete":
          this.alertDelete = true;
          break;
      }
      setTimeout(() => {
        this.alertCreate = false;
        this.alertDelete = false;
      }, 5000);
    },

    ocDialogAddUser() {
      this.dialogAddUser
        ? (this.dialogAddUser = false)
        : (this.dialogAddUser = true);
    },

    addUser(email, nom, prenom, isAdmin) {
      this.dialogAddUser = false;
      this.userAlert = email;
      this.addUserReq(email, nom, prenom, isAdmin);
      this.alertDisplay("create");
    },
    refreshUsers() {
      this.getUsersList();
    },
    refreshRoles() {
      this.getRolesList();
    },
    deleteUser(name) {
      this.userAlert = name;
      this.deleteUserReq(name);
      this.alertDisplay("delete");
    },
  },
};
</script>

<style></style>
