<template>
  <v-container grid-list-xs>
    <v-card>
      <v-card-title primary-title> Log In </v-card-title>
      <v-card-text>
        <div>
          Si vous n'avez pas de pop-up, veuillez vérifier que votre navigateur
          ne la bloque pas.
        </div>
        <div @click="manualLogIn()" style="cursor: pointer"><u>Cliquez ici pour afficher la pop-up</u></div>
      </v-card-text>
      <v-card-text v-if="this.errNotAuchan" color="error">
        Ceci n'est pas un compte Auchan.
        Veuillez vous connecter avec un compte auchan.
      </v-card-text>
      <v-card-actions> </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import firebase from "../firebase/firebase";
import scriptLogin from "../scripts/login";
import store from "../store/store";

export default {
  name: "LOGIN",
  mixins: [firebase, scriptLogin],

  data() {
    return {
      errNotAuchan: false,
      loggedIn: false,
      userGCP: [],
    };
  },

  methods: {
    toggleNotAuchan() {
      this.errNotAuchan = !this.errNotAuchan;
    },

    async manualLogIn() {
      await this.logIn();
    },

    async afterLogIn(param) {
      let tmpUser = param;
      await this.getUser(tmpUser);
      tmpUser["id"] = this.userGCP.id;
      await store.dispatch("setIsAdminRDExpo", this.userGCP.isAdminRDExpo);
      await store.dispatch("setUser", tmpUser);
      this.loggedIn = true;
    },
  },

  mounted: async function () {
    await this.logIn();
  },

  watch: {
    loggedIn(newValue, oldValue) {
      if (newValue) {
        try {
          this.$router.push("/rdo");
        } catch (error) {
          console.log(error);
        }
      }
    },
  },
};
</script>

<style></style>
