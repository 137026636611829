<template>
  <div>
    <v-dialog
      :value="dialogPurge"
      :overlay="false"
      max-width="700px"
      transition="dialog-transition"
      persistent
      v-if="rdoSelected !== null"
    >
      <v-card v-if="this.apiRDO !== undefined">
        <v-card-title primary-title> Purge </v-card-title>
        <v-card-text>
          <b>
            RDO sélectionné:<br />
            {{
              rdoSelected["_fieldsProto"]["Nomenclature GCP"]["stringValue"]
            }}
          </b>
        </v-card-text>
        <v-card-text v-if="isProjectId()==true">
          projectId: {{ apiRDO.projectId }}
        </v-card-text>
        <v-card-text>
          Paramètres:
          <ul>
            <li v-for="el in this.addedParams" :key="el.key">
              {{ el.key }}={{ el.value }}
            </li>
          </ul>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="purgeVerif()">Purger</v-btn>
          <v-btn text @click="toggleDialogPurge()">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Boîte de dialogue "En êtes-vous sûr" -->
    <v-dialog
      :value="dialogSur"
      :overlay="false"
      max-width="700px"
      transition="dialog-transition"
      persistent
      v-if="rdoSelected !== null"
    >
      <v-card v-if="this.apiRDO !== undefined">
        <v-card-title primary-title>En êtes-vous sûr ?</v-card-title>
        <v-card-text>
          Vous avez sélectionné {{numberOfDoc}} document{{numberOfDoc > 1 ? "s" : ""}} qui seront supprimé{{numberOfDoc > 1 ? "s" : ""}} en cas de purge. Voulez-vous continuer ?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="purge()">Oui, je suis sûr</v-btn>
          <v-btn text @click="toggleDialogSur()">Fermer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Boîte de dialogue visible en QUALIF et DEV -->
    <v-dialog
      :value="dialogVerif"
      :overlay="false"
      max-width="700px"
      transition="dialog-transition"
      persistent
      v-if="rdoSelected !== null && (env === 'HPRD' || env === 'DEV')"
    >
      <v-card v-if="this.apiRDO !== undefined">
        <v-card-title primary-title>Voulez-vous vraiment purger les {{numberOfDoc}} documents ?</v-card-title>
        <v-card-text>
          Vous n'avez sélectionné aucun paramètre. Si vous décidez de purger, c'est le RDO entier qui sera purgé. Voulez-vous continuer ?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="purge()">Oui, je veux purger TOUT le RDO</v-btn>
          <v-btn text @click="toggleDialogVerif()">Fermer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Boîte de dialogue visible en PROD -->
    <v-dialog
      :value="dialogVerif"
      :overlay="false"
      max-width="700px"
      transition="dialog-transition"
      persistent
      v-if="rdoSelected !== null && env === 'PROD'"
    >
      <v-card v-if="this.apiRDO !== undefined">
        <v-card-title primary-title>Purge impossible</v-card-title>
        <v-card-text>
          La purge n'est pas disponible en n'ayant sélectionné aucun paramètre. Veuillez contacter l'équipe RDO pour supprimer la totalité des données du RDO.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="toggleDialogVerif()">Fermer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import store from "./../../store/store";

export default {
  name: "serv-purge",
  props: {
    dialogPurge: Boolean,
    toggleDialogPurge: Function,
    rdoSelected: Object,
    addedParams: Array,
    apiRDO: Object,
    numberOfDoc: Number,
  },
  data() {
    return {
      env: store.getters.env,
      dialogVerif: false,
      dialogSur: false
    };
  },
  methods: {
    async purge() {
      await this.$emit("purge");
      this.dialogVerif = false
      this.dialogSur = false
      this.toggleDialogPurge()
    },
    purgeVerif() {
      if (this.addedParams.length === 0) {
        this.dialogVerif = true;
      } else if (this.addedParams.length > 0) {
        this.dialogSur = true;
      }
    },
    toggleDialogVerif() {
      this.dialogVerif = false
      this.toggleDialogPurge()
    },
    toggleDialogSur() {
      this.dialogSur = false
      this.toggleDialogPurge()
    },
    isProjectId() { //Fonction de test catch("TypeError: Cannot read properties of null")
      try {
        if (this.apiRDO.projectId == true) {
          return true
        }
        else {
          return false
        }
      }
      catch (error) {
        if (error instanceof TypeError) {
          return false
        }
        else {
          console.log("[ServPurge.vue ] - isProjectId() - Probléme non reconnu " + error)
        }
      }
    }
  },
};
</script>

<style></style>
