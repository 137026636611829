<template>
  <div>
    <rdo-nav-bar
      v-bind:rdoSelected="rdoSelected"
      v-bind:apiParams="apiParams"
      v-bind:addedParams="addedParams"
      v-bind:subRequests="subRequests"
      v-bind:apiRDO="apiRDO"
      v-bind:numberOfDoc="RDO.length"
      @refreshRdo="refreshRdo"
    />

    <rdo-api-selection
      v-bind:loadingList="loadingList"
      v-bind:listRdo="listRdo"
      v-bind:apiParams="apiParams"
      v-bind:addedParams="addedParams"
      v-bind:apiRDO="apiRDO"
      @updateRdoSelected="updateRdoSelected"
    />

    <rdo-params
      v-bind:rdoSelected="rdoSelected"
      v-bind:rdoKeys="rdoKeys"
      v-bind:nestedList="nestedList"
      v-bind:connectors="connectors"
      v-bind:addedParams="addedParams"
      @addParameter="addParameter"
      @deleteParameter="deleteParameter"
      @addSearch="addSearch"
    />

    <rdo-data-table
      v-bind:loadingRDO="loadingRDO"
      v-bind:RDO="RDO"
      v-bind:headersRDO="headersRDO"
      v-bind:dataRDO="dataRDO"
      v-bind:contentRange="contentRange"
      @updateDataRDO="updateDataRDO"
      @refreshRdo="refreshRdo"
    />

    <!-- <v-btn color="success" @click="dwlTest()">text</v-btn> -->
  </div>
</template>

<script>
import rdoNavBar from "../components/rdoNavBar.vue";
import rdoApiSelection from "../components/rdoAPISelection.vue";
import rdoParams from "../components/rdoParams.vue";
import rdoDataTable from "../components/rdoDataTable.vue";

import rdoRequests from "../scripts/rdoRequests";
import rdoScripts from "../scripts/rdoScripts";

import subscribe from "../scripts/subscribe";

export default {
  name: "RDO",
  mixins: [rdoRequests, rdoScripts, subscribe],
  components: {
    rdoNavBar,
    rdoApiSelection,
    rdoParams,
    rdoDataTable,
  },

  data() {
    return {
      rdoSelected: null,
      listRdo: [],
      listUrl: [],
      loadingList: true,
      apiRDO: null,
      apiParams: {
        range: "0-49",
        fields: "metadata(technicalId,processDate)",
      },
      loadingRDO: false,
      RDO: [],
      dataRDO: null,
      headersRDO: [
        {
          text: "Technical ID",
          value: "metadata.technicalId",
        },
        {
          text: "Process Date",
          value: "metadata.processDate",
        },
        { value: "actions", sortable: false, align: 'end' },
      ],
      rdoKeys: [],
      nestedList: [],
      connectors: [
        ["", "="],
        ["!", "!="],
        [":lt", "lt"],
        [":lte", "lte"],
        [":gt", "gt"],
        [":gte", "gte"],
      ],
      addedParams: [],
      subRequests: [],
      contentRange: 0,
    };
  },

  methods: {
    dwlTest() {
      const url = window.URL.createObjectURL(
        new Blob([JSON.stringify(this.RDO, null, 2)], {
          type: "application/json",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "test");
      document.body.appendChild(link);
      link.click();
    },
  },

  watch: {
    apiParams(params) {
      this.addedParams.splice(0, this.addedParams.length);
      Object.keys(params).forEach((element) => {
        if (
          element != "range" &&
          element != "fields" &&
          element != "default_operator" &&
          element != "q"
        ) {
          this.addedParams.push({ key: element, value: params[element] });
        }
      });
    },
  },
  mounted: async function () {
    setTimeout(() => {}, 5000);
    await this.getListRdo();
    await this.getListUrl();
    await this.getSubRequests();
  },
};
</script>

<style></style>
