<template>
  <div>
    <v-dialog
      :value="dialogAlim"
      :overlay="false"
      max-width="700px"
      transition="dialog-transition"
      persistent
      v-if="rdoSelected !== null"
    >
      <v-card v-if="this.apiRDO !== undefined">
        <v-card-title primary-title>
          Alimentation RDO
        </v-card-title>
        <v-card-text>
          <small>* Attention, l'alimentation est limitée à 1000 documents. Pour alimenter une plus grande quantité, contactez l'équipe RDO.</small><br /><br />
          <b>Vous êtes sur le point d'effectuer une alimentation de la Prod vers la Qualif</b><br />
          <u>RDO à alimenter :</u> {{ apiRDO.projectId }}<br />
          <u>RDO de prod source :</u> {{ `arf-rdo-${rdoSelected["_fieldsProto"]["Nomenclature GCP"]["stringValue"].includes("v1-")
            ? rdoSelected["_fieldsProto"]["Nomenclature GCP"]["stringValue"].slice(0, rdoSelected["_fieldsProto"]["Nomenclature GCP"]["stringValue"].indexOf('v1-') + 2)
            : rdoSelected["_fieldsProto"]["Nomenclature GCP"]["stringValue"]}`
          }}<br />
        </v-card-text>
        <v-card-text>
          Paramètres : {{ this.addedParams.length === 0 ? "Aucun paramètre sélectionné" : "" }}
          <ul v-if="this.addedParams.length !== 0">
            <li v-for="el in this.addedParams" :key="el.key">
              {{ el.key }}={{ el.value }}
            </li>
          </ul>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            @click="alimVerif()"
            >Alim</v-btn
          >
          <v-btn text @click="toggleDialogAlim()">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Boîte de dialogue quand aucun paramètre -->
    <v-dialog
      :value="dialogVerif"
      :overlay="false"
      max-width="700px"
      transition="dialog-transition"
      persistent
    >
      <v-card v-if="this.apiRDO !== undefined">
        <v-card-title primary-title>Aucun paramètre</v-card-title>
        <v-card-text>
          Vous n'avez sélectionné aucun paramètre. Si vous décidez de lancer l'alimentation, Ce sont les 1000 documents les plus récents qui seront repris en cas d'alimentation. Voulez-vous continuer ?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="alim()">Oui, je veux alimenter le RDO</v-btn>
          <v-btn text @click="toggleDialogVerif()">Fermer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Boîte de dialogue "En êtes-vous sûr" -->
    <v-dialog
      :value="dialogSur"
      :overlay="false"
      max-width="700px"
      transition="dialog-transition"
      persistent
      v-if="rdoSelected !== null"
    >
      <v-card v-if="this.apiRDO !== undefined">
        <v-card-title primary-title
          >Êtes-vous sûr d'alimenter ses documents ?</v-card-title
        >
        <v-card-text>
          Vous avez sélectionné <b>{{ numberOfDoc }} document{{
            numberOfDoc > 1 ? "s" : ""
          }}</b>{{ numberOfDoc > 1000 ? ". Ce sont les 1000 documents les plus récents" : "" }}
          qui seront repris en cas d'alimentation. Voulez-vous continuer ?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="alim()">Oui, je suis sûr</v-btn>
          <v-btn text @click="toggleDialogSur()">Fermer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Boîte de dialogue "In progress" -->
    <v-dialog
      :value="dialogInProgress"
      :overlay="false"
      max-width="700px"
      transition="dialog-transition"
      persistent
      v-if="rdoSelected !== null"
    >
      <v-card v-if="this.apiRDO !== undefined">
        <v-card-title primary-title
          >Alimentation en cours</v-card-title
        >
        <v-card-text>
          Les <b>{{ numberOfDoc > 1000 ? 1000 : numberOfDoc }} document{{
            numberOfDoc > 1 ? "s" : ""
          }}</b> sont en cours d'alimentation depuis le RDO de prod "{{ `arf-rdo-${rdoSelected["_fieldsProto"]["Nomenclature GCP"]["stringValue"].includes("v1-")
            ? rdoSelected["_fieldsProto"]["Nomenclature GCP"]["stringValue"].slice(0, rdoSelected["_fieldsProto"]["Nomenclature GCP"]["stringValue"].indexOf('v1-') + 2)
            : rdoSelected["_fieldsProto"]["Nomenclature GCP"]["stringValue"]}`
          }}" sur le RDO hors-prod "{{ apiRDO.projectId }}".
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Boîte de dialogue "Error : RDO prod not found or 0 doc" -->
    <v-dialog
      :value="dialogError"
      :overlay="false"
      max-width="700px"
      transition="dialog-transition"
      persistent
      v-if="rdoSelected !== null"
    >
      <v-card v-if="this.apiRDO !== undefined">
        <v-card-title primary-title
          >Erreur lors de la récupération des documents de prod</v-card-title
        >
        <v-card-text>
          Le RDO de prod "{{ `arf-rdo-${rdoSelected["_fieldsProto"]["Nomenclature GCP"]["stringValue"].includes("v1-")
            ? rdoSelected["_fieldsProto"]["Nomenclature GCP"]["stringValue"].slice(0, rdoSelected["_fieldsProto"]["Nomenclature GCP"]["stringValue"].indexOf('v1-') + 2)
            : rdoSelected["_fieldsProto"]["Nomenclature GCP"]["stringValue"]}`
          }}" n'existe pas où aucun document n'a été trouvé.
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import store from "../../store/store";
const axios = require("axios");

export default {
  name: "serv-alim",
  props: {
    dialogAlim: Boolean,
    toggleDialogAlim: Function,
    rdoSelected: Object,
    apiRDO: Object,
    addedParams: Array
  },
  data: () => ({
    numberOfDoc: 0,
    url: `${store.getters.apiUrl}`,
    env: store.getters.env,
    dialogVerif: false,
    dialogSur: false,
    dialogInProgress: false,
    dialogError: false
  }),
  methods: {
    async alim() {
      this.dialogVerif = false;
      this.dialogSur = false;
      this.dialogInProgress = true;
      setTimeout(() => {
        this.dialogInProgress = false;
      }, 4000);
      this.doAlim();
      this.toggleDialogAlim();
    },
    async error() {
      this.dialogVerif = false;
      this.dialogSur = false;
      this.dialogError = true;
      setTimeout(() => {
        this.dialogError = false;
      }, 4000);
      this.toggleDialogAlim();
    },
    async alimVerif() {
      const queryParams = this.queryParams();

      let config = {
        headers: {
          authorization: `Bearer ${store.getters.user.token}`,
        },
        data: {
          params: Array.isArray(queryParams) ? queryParams.join('') : queryParams,
          projectProd: `arf-rdo-${this.rdoSelected["_fieldsProto"]["Nomenclature GCP"]["stringValue"].includes("v1-")
            ? this.rdoSelected["_fieldsProto"]["Nomenclature GCP"]["stringValue"].slice(0, this.rdoSelected["_fieldsProto"]["Nomenclature GCP"]["stringValue"].indexOf('v1-') + 2)
            : this.rdoSelected["_fieldsProto"]["Nomenclature GCP"]["stringValue"]}`
        },
      };
      let request = `${this.url}/countDocsProd`;
      axios.post(request, config)
        .then((results) => {
          if (typeof results.data.data === "string" || results.data.data === undefined) return this.error()
          this.numberOfDoc = results.data.data;
          if (this.addedParams.length === 0) {
            this.dialogVerif = true;
          } else if (this.addedParams.length > 0) {
            this.dialogSur = true;
          }
        })
        .catch((error) => {
          console.log(`[ ServAlim.vue ] - alimVerif() - error => `, error);
        });
    },
    toggleDialogSur() {
      this.dialogSur = false;
      this.toggleDialogAlim();
    },
    toggleDialogVerif() {
      this.dialogVerif = false
      this.toggleDialogAlim()
    },
    queryParams() {
      return this.addedParams.length > 0
        ? this.addedParams.map((param, index) => {
          if (index === 0) return `?${param.key}=${param.value}`
          return `&${param.key}=${param.value}`
        })
        : "?"
    },
    async doAlim() {
      const queryParams = this.queryParams();

      let config = {
        headers: {
          authorization: `Bearer ${store.getters.user.token}`,
        },
        data: {
          params: Array.isArray(queryParams) ? queryParams.join('') : queryParams,
          project: this.apiRDO.projectId,
          projectProd: `arf-rdo-${this.rdoSelected["_fieldsProto"]["Nomenclature GCP"]["stringValue"].includes("v1-")
            ? this.rdoSelected["_fieldsProto"]["Nomenclature GCP"]["stringValue"].slice(0, this.rdoSelected["_fieldsProto"]["Nomenclature GCP"]["stringValue"].indexOf('v1-') + 2)
            : this.rdoSelected["_fieldsProto"]["Nomenclature GCP"]["stringValue"]}`,
          inputHprd: this.apiRDO.input
        },
      };

      let request = `${this.url}/alim`;
      axios
        .post(request, config)
        .then((results) => {
          console.log("done => " + JSON.stringify(results));
          this.$emit("refreshRdo");
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style></style>
