const axios = require("axios");
import store from "../store/store";

const url = `${store.getters.apiUrl}`;

export default {
  methods: {
    getRolesList() {
      this.loadingRoles = true;
      let config = {
        headers: {
          authorization: `Bearer ${store.getters.user.token}`,
        },
      };
      let request = `${url}/roles`;
      axios
        .get(request, config)
        .then((results) => {
          this.roles = results.data;
          this.loadingRoles = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getUsersList() {
      this.loadingUsers = true;
      let config = {
        headers: {
          authorization: `Bearer ${store.getters.user.token}`,
        },
      };
      let request = `${url}/users`;
      axios
        .get(request, config)
        .then((results) => {
          this.users = results.data;
          this.loadingUsers = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },


    addUserReq(mail, nom, prenom, admin) {
      let obj = {
        mail: mail,
        name: nom.toUpperCase(),
        firstname: prenom.toUpperCase(),
        isAdminRDExpo: admin,
      };
      let config = {
        headers: {
          authorization: `Bearer ${store.getters.user.token}`,
        },
      };
      let request = `${url}/users`;
      axios
        .post(request, obj, config)
        .then((results) => {
          this.getUsersList();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    deleteUserReq(name) {
      let config = {
        headers: {
          authorization: `Bearer ${store.getters.user.token}`,
        },
      };
      let request = `${url}/users`;
      console.log(name);
      axios
        .delete(request, { data: { name } }, config)
        .then((results) => {
          this.getUsersList();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
