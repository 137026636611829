<template>
  <div>
    <v-dialog
      :value="dialogTov"
      :overlay="false"
      max-width="700px"
      transition="dialog-transition"
      persistent
    >
      <v-card>
        <v-card-title primary-title>Mettre à jour une table de valeur</v-card-title>
        <!-- tov change form in qualif -->
        <v-card-text v-if="env === 'HPRD' || env === 'DEV'">
          <v-container>
            <v-textarea
              v-model="tov"
              label="JSON de la Table de valeur à ajouter / modifier *"
              required
            ></v-textarea>
          </v-container>
          <small>* Champs Requis</small><br />
          <small>
            Exemple table de valeur : <br />
            {<br />
            "tableOfValuesTable": "siteFormat",<br />
            "tableOfValuesList": [<br />
            { "tableOfValuesCode": "DRL", "tableOfValuesDefinition": "DRIVE LOCKER" },<br />
            { "tableOfValuesCode": "DIF", "tableOfValuesDefinition": "Différentiation" }<br />
            ]<br />
            }
          </small>
          <div class="v-alert error" v-if="errorTov">
            <p>Une erreur s'est produite : {{ this.errorMessage }}</p>
          </div>
          <div>
            <p class="v-alert success" v-if="tovOk">Mise à jour effectuée</p>
          </div>
          <div class="text-center">
            <v-progress-circular
              v-if="loader === true && env === 'DEV'"
              :size="70"
              :width="5"
              color="amber"
              indeterminate
            ></v-progress-circular>
            <v-progress-circular
              v-if="loader === true && env === 'HPRD'"
              :size="70"
              :width="5"
              color="green"
              indeterminate
            ></v-progress-circular>
            <v-progress-circular
              v-if="loader === true && env === 'PROD'"
              :size="70"
              :width="5"
              color="blue"
              indeterminate
            ></v-progress-circular>
          </div>
        </v-card-text>
        <v-card-actions v-if="env === 'HPRD' || env === 'DEV'">
          <v-spacer></v-spacer>
          <v-btn text @click="clear();toggleDialogTov();">Fermer</v-btn>
          <v-btn
            color="green lighten-2"
            aria-disabled="false"
            v-if="this.tov !== ''"
            text
            @click.native="tovExist();"
          >Mettre à jour</v-btn>
        </v-card-actions>

        <!-- tov change form in prod -->
        <v-card-text v-if="env === 'PROD'">
          <v-container>
            <v-text-field
              v-model="nomTov" 
              label="Nom de la table de valeur" 
              required
            ></v-text-field>
          </v-container>
          <small>* Champs Requis</small><br />
          <div class="v-alert error" v-if="errorTov">
            <p>Une erreur s'est produite : {{ this.errorMessage }}</p>
          </div>
          <div>
            <p class="v-alert success" v-if="tovOk">Mise à jour effectuée</p>
          </div>
        </v-card-text>
        <v-card-actions v-if="env === 'PROD'">
          <v-spacer></v-spacer>
          <v-btn text @click="clear();toggleDialogTov();">Fermer</v-btn>
          <v-btn
            color="green lighten-2"
            aria-disabled="false"
            v-if="this.nomTov !== ''"
            text
            @click.native="pushTovProd();"
          >Mettre à jour</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Boîte de dialogue "Voulez-vous vraiment modifier / créer ?" -->
    <v-dialog
      :value="dialogTovExist"
      :overlay="false"
      max-width="700px"
      transition="dialog-transition"
      persistent
    >
      <v-card>
        <v-card-title primary-title>En êtes-vous sûr ?</v-card-title>
        <v-card-text>
          {{ this.handleTovExist === true ? "La table de valeur existe déjà. Voulez-vous la modifier ?" : "La table de valeur n'existe pas. Voulez-vous la créer ?" }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="pushTov(); toggleDialogTovExist();" v-if="env === 'HPRD' || env === 'DEV'">Oui</v-btn>
          <v-btn color="error" @click="pushTovProd(); toggleDialogTovExist();" v-if="env === 'PROD'">Oui</v-btn>
          <v-btn text @click="clear(); toggleDialogTovExist(); toggleDialogTov();">Non</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import store from "./../../store/store";
import rdoScripts from "./../../scripts/rdoScripts";
import rdoRequests from "./../../scripts/rdoRequests";

export default {
  name: "serv-tov",
  mixins: [rdoScripts, rdoRequests],
  props: {
    dialogTov: Boolean,
    toggleDialogTov: Function,
    rdoSelected: Object,
  },
  data() {
    return {
      env : store.getters.env,
      errorTov: false,
      errorMessage: "",
      tovOk: false,
      tov: "",
      nomTov: "",
      graviteeKey: "",
      handleTovExist: false,
      dialogTovExist: false,
      loader: false
    };
  },
  methods: {
    clear() {
      this.errorTov = false
      this.errorMessage = ""
      this.tovOk = false
      this.tov = ""
      this.handleTovExist = false
    },

    toggleDialogTovExist() {
      this.dialogTovExist = !this.dialogTovExist;
    },

    async tovExist() {
      this.errorTov = false;

      // check if this.tov is a JSON, else errortov true
      const jsonValidate = this.isJson(this.tov);

      if (jsonValidate) {
        const technicalId = JSON.parse(this.tov).tableOfValuesTable
        const response = await this.tovIsExist(technicalId);

        if (!response) {
          this.errorMessage = "La requête a échoué"
          this.errorTov = true;
        }

        if (response.status === 200) {
          this.handleTovExist = true
          return this.dialogTovExist = true
        } else if (response.status === 202) {
          this.handleTovExist = false
          return this.dialogTovExist = true
        }
      } else {
        this.errorMessage = "La saisie n'est pas au format JSON !"
        return this.errorTov = true;
      }
    },

    async pushTov() {
      this.loader = true;
      this.errorTov = false;
      this.tovOk = false;
      const request = await this.majTov(JSON.parse(this.tov));

      if (request.status !== 202) {
        this.loader = false;
        this.errorMessage = "La requête a échoué"
        this.errorTov = true;
      } else {
        this.loader = false;
        this.tovOk = true;
        setTimeout(() => {
          this.toggleDialogTov();
          this.clear();
          this.$emit("refreshRdo");
        }, 2000);
      }
    },

    async pushTovProd() {
      this.errorTov = false;
      this.tovOk = false;
      const request = await this.majTovProd(this.nomTov);

      if (request.status !== 202) {
        this.errorMessage = "La requête a échoué"
        this.errorTov = true;
      } else {
        this.tovOk = true;
        setTimeout(() => {
          this.toggleDialogTov();
          this.clear();
          this.$emit("refreshRdo");
        }, 2000);
      }
    },

    isJson(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },
  },
  mounted: function () {
  },
};
</script>

<style></style>
